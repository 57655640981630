<template>
  <div class="line-items">
    <base-text type="heading">
      <render-content :data="{ itemsCount, itemPluralization }">
        {{ content.itemListHeader }}
      </render-content>
    </base-text>

    <line-item
      v-for="item in items"
      :key="item.id"
      :item="item"
      class="line-items__list-item list-item"
    />
  </div>
</template>

<script setup>
import { BaseText } from '@loophq/design-system';
import LineItem from './LineItem.vue';
import { computed } from 'vue';

const props = defineProps({
  items: Array,
  content: Object,
});

const itemsCount = computed(() => {
  return props.items?.length;
});

const itemPluralization = computed(() => {
  if (itemsCount.value > 1) {
    return props.content.pluralItem;
  }

  return props.content.singularItem;
});
</script>
